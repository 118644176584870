import React from "react";
import AuthService from "./services/AuthService";
import Home from "./pages/Home";
import SignInSide from "./pages/SignInSide";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./Helpers/PrivateRoute";

export default function App() {
  const isAuthenticated = AuthService.getCurrentUser();
  return (
    <div>
      <Routes>
        <Route path="/" element={<SignInSide />} />
        <Route
          exact
          path="/dispatch"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <Home />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<SignInSide />} />
      </Routes>
    </div>
  );
}
