import React, { useContext,useState } from "react";
import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
// import { useConfirm } from "material-ui-confirm";
import TransferTourneeDialog from "./TransferTourneeDialog";
import "../css/RightDrawerLivreurTourneesListItem.css"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function RightDrawerLivreurTourneesListItem({
  tourneeId,
  livreur,
}) {
  const [isTransferTourneeDialogShowing,setIsTransferTourneeDialogShowing] = useState(false);
  // const confirm = useConfirm();
  const { 
    stops, 
    removeTourneeToLivreurByIdHandler,
    invisibleTournees,
    setInvisibleTournees,
  } =
    useContext(ExpeditionContext);
  const totalStopsInTournee = stops.filter(
    (m) => m.tourneeId == tourneeId && m.isDone
  ).length;

  const confirmRemoveTourneeToLivreurByIdEvent = (livreurId, tourneeId) => {
    confirmAlert({
      title: 'Confirmer',
      message: 'êtes-vous sûr de vouloir supprimer la tournée.',
      buttons: [
        {
          label: 'Confirmer',
          onClick: () => removeTourneeToLivreurByIdHandler(livreurId, tourneeId)
        },
        {
          label: 'Annuler',
          onClick: () => console.log("Deletion cancelled.")
        }
      ]
    });

  };
  const changeTourneeVisibliteEvent = ()=>{
      if(invisibleTournees.includes(tourneeId)){
        setInvisibleTournees(invisibleTournees.filter(m => m != tourneeId))
      }else{
        setInvisibleTournees(invisibleTournees => [...invisibleTournees,tourneeId])
      }
  }
  const transferTourneeByTourneeId =() => {
    setIsTransferTourneeDialogShowing(true)
  }
  const tourneeVisibliteIcon = invisibleTournees.includes(tourneeId) ? "las la-eye-slash cursor-pointer icon":"las la-eye cursor-pointer icon"

  return (
    <div className="d-flex tournee-info-element">
      <div className="font-weight-bold" >{tourneeId}</div>
      <div className="stops-count-text" > {totalStopsInTournee} </div>
      <i className={tourneeVisibliteIcon} onClick={changeTourneeVisibliteEvent}> </i>
      <i className="las la-pen cursor-pointer icon" onClick={transferTourneeByTourneeId} > </i>
      <i className="las la-trash-alt cursor-pointer icon" 
      onClick={() => confirmRemoveTourneeToLivreurByIdEvent(livreur.id, tourneeId)} ></i>
      {isTransferTourneeDialogShowing && <TransferTourneeDialog fromlivreur={livreur} tourneeId={tourneeId} setDisplay={setIsTransferTourneeDialogShowing} />}
      
    </div>
  );
}
