import React, { createContext, useState, useEffect } from "react";
import mapDefaultProps from "../configuration/mapDefaultProps";

export const MapContext = createContext();

export const MapContextProvider = ({ children }) => {
  const [mapParams, setMapParams] = useState({
    center: mapDefaultProps.center,
    zoom: mapDefaultProps.zoom,
  });

  const [googleMap, setGoogleMap] = useState();

  const initMap = () => {
    googleMap.map.data.setStyle({
      fillColor: "#fff",
      fillOpacity: 1,
      clickable: false,
    });
  };

  useEffect(() => {
    if (googleMap) initMap();
  }, [googleMap]);

  const onMapChange = ({ center, zoom }) => {
    setMapParams({
      center: center,
      zoom: zoom,
    });
  };
  return (
    <MapContext.Provider
      value={{
        googleMap,
        setGoogleMap,
        initMap,
        onMapChange,
        mapParams,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
