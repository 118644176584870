import React, { useContext, useState, useEffect } from "react";
import Modal from "react-modal";

import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
import Select, { components } from "react-select";

const { SingleValue, Option } = components;

const IconSingleValue = (props) => (
  <SingleValue {...props}>
    <div className="d-flex align-items-center">
      <img
        src={props.data.image}
        alt={props.data.fullname}
        style={{
          height: "30px",
          width: "30px",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      />
      {props.data.fullname}
    </div>
  </SingleValue>
);

const IconOption = (props) => (
  <Option {...props}>
    <div className="d-flex align-items-center">
      <img
        src={props.data.image}
        alt={props.data.fullname}
        style={{
          height: "30px",
          width: "30px",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      />
      {props.data.fullname}
    </div>
  </Option>
);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export default function TransferTourneeDialog({
  fromlivreur,
  tourneeId,
  setDisplay,
}) {
  const [open, setOpen] = useState(false);
  const [selectedLivreurIdOption, setSelectedLivreurIdOption] = useState();
  const [selectedLivreurOption, setSelectedLivreurOption] = useState();
  const [selectedTourneeOptionId, setSelectedTourneeOptionId] = useState();

  const {
    livreurs,
    selectedVehiculeTypeId,
    transferTourneeVersNouveauLivreurHandler,
    transferTourneeToLivreurWithNewTourneeHandler,
    invisibleTournees,
  } = useContext(ExpeditionContext);

  const closeEvent = () => {
    fromlivreur = null;
    setOpen(false);
    setDisplay(false);
  };

  const changeselectedLivreurEvent = (livreur) => {
    const livreurId = livreur.id;
    setSelectedLivreurIdOption(livreurId);
    const livreurInfos = livreurs.find((m) => m.id == livreurId);
    setSelectedLivreurOption(livreurInfos);
  };

  const changeselectedTourneeIdEvent = (e) => {
    const value = e.target.value;
    setSelectedTourneeOptionId(value);
  };

  const transferTourneeToLivreurEvent = () => {
    if (selectedLivreurIdOption && selectedTourneeOptionId) {
      transferTourneeVersNouveauLivreurHandler(
        fromlivreur.id,
        tourneeId,
        selectedLivreurOption,
        selectedTourneeOptionId
      );
      closeEvent();
    }
  };

  const transferTourneeToLivreurWithNewTourneeEvent = () => {
    console.log(selectedLivreurOption, tourneeId, fromlivreur);
    if (selectedLivreurIdOption) {
      transferTourneeToLivreurWithNewTourneeHandler(
        fromlivreur.id,
        tourneeId,
        selectedLivreurOption
      );
      closeEvent();
    }
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeEvent}
      style={customStyles}
      aria-labelledby="customized-dialog-title"
    >
      <h4 onClose={closeEvent}>Affectation des colis</h4>
      <hr className="mt-10" />
      <Select
        className="mt-10"
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 2000 }),
        }}
        placeholder="Sélectionner un livreur"
        components={{ SingleValue: IconSingleValue, Option: IconOption }}
        options={livreurs.filter(
          (m) => m.vehiculetypeId == selectedVehiculeTypeId && m.isVisible
        )}
        defaultValue={selectedLivreurIdOption}
        onChange={changeselectedLivreurEvent}
        menuPortalTarget={document.body}
        menuShouldBlockScroll={true}
        getOptionValue={(option) => option.id}
      />

      {selectedLivreurOption && (
        <div
          aria-labelledby="demo-radio-buttons-group-label"
          value={selectedTourneeOptionId ?? ""}
          onChange={changeselectedTourneeIdEvent}
        >
          {selectedLivreurOption.tournees
            .filter((m) => !invisibleTournees.includes(m))
            .map((tournee) => (
              <div
                key={tournee}
                className="card my-5 d-flex align-items-center"
              >
                <input
                  type="radio"
                  value={tournee}
                  name="tourneeId"
                  className="mr-4"
                />
                <label htmlFor={tournee}>Tournée N° {tournee}</label>
              </div>
            ))}
        </div>
      )}
      <div className="mt-20">
        <button className="cancel-btn" onClick={closeEvent}>
          Annuler
        </button>
        <button
          disabled={!selectedTourneeOptionId}
          onClick={transferTourneeToLivreurEvent}
          className="validate-btn"
        >
          Affecter
        </button>
        <button
          disabled={!selectedLivreurIdOption}
          onClick={transferTourneeToLivreurWithNewTourneeEvent}
          className="validate-btn"
        >
          Nouvelle tournée
        </button>
      </div>
    </Modal>
  );
}
