import { useContext, useEffect, useState } from "react";
import { MapContext } from "../contexts/MapContextProvider";
import { OverlayMapContext } from "../contexts/OverlayMapContextProvider";

export default function DrawingManager(props) {
  const [dm, setDrawingManager] = useState();

  const { googleMap } = useContext(MapContext);
  const { addOverlayObject } = useContext(OverlayMapContext);
  const drawingStyle = {
    fillColor: "#8fc1f0",
    strokeWeight: 1,
    fillOpacity: 0.45,
    editable: false,
    draggable: false,
    strokeColor: "#1E90FF",
    zIndex: 1,
  };
  const initDrawingManager = () => {
    const drawingManager = new googleMap.maps.drawing.DrawingManager({
      drawingMode: googleMap.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: googleMap.maps.ControlPosition.TOP_LEFT,
        drawingModes: [
          googleMap.maps.drawing.OverlayType.CIRCLE,
          googleMap.maps.drawing.OverlayType.POLYGON,
          googleMap.maps.drawing.OverlayType.RECTANGLE,
        ],
      },
      markerOptions: {
        icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      },
      rectangleOptions: drawingStyle,
      circleOptions: drawingStyle,
      polygonOptions: drawingStyle,
    });
    googleMap.map.data.setStyle({
      fillColor: "#fff",
      fillOpacity: 1,
      clickable: false,
    });
    drawingManager.setMap(googleMap.map);
    setDrawingManager(drawingManager);
  };

  useEffect(() => {
    if (dm) {
      overlayCompleteEvent();
    }
  }, [dm]);

  const overlayCompleteEvent = () => {
    googleMap.maps.event.addListener(
      dm,
      "overlaycomplete",
      ({ overlay, type }) => {
        addOverlayObject(overlay, type);
      }
    );
  };

  useEffect(() => {
    if (googleMap) initDrawingManager();
  }, [googleMap]);

  return <>{props.children}</>;
}
