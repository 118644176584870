const mapDefaultProps = {
  key: "AIzaSyBWibdyv5FvIb17bOxDTs6cE4r7UDdBQlU",
  center: {
    lat: 33.6021812,
    lng: -7.6214218,
  },
  libraries: ["drawing,geometry"],
  zoom: 14,
};

export default mapDefaultProps;
