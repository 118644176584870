import GrahamScan from "@lucio/graham-scan";
export function lineDistance(point1, point2) {
  var xs = 0;
  var ys = 0;

  xs = point2.lat - point1.lat;
  xs = xs * xs;

  ys = point2.lng - point1.lng;
  ys = ys * ys;

  return Math.sqrt(xs + ys);
}

export function getSortPolygonPoints(points) {
  // const grahamScan = new GrahamScan();
  // grahamScan.setPoints(points);
  // return grahamScan.getHull();

  // calculate max and min x and y
  var minX = points[0].lat;
  var maxX = points[0].lat;
  var minY = points[0].lng;
  var maxY = points[0].lng;

  for (var i = 1; i < points.length; i++) {
    if (points[i].lat < minX) minX = points[i].lat;
    if (points[i].lat > maxX) maxX = points[i].lat;
    if (points[i].lng < minY) minY = points[i].lng;
    if (points[i].lng > maxY) maxY = points[i].lng;
  }

  // choose a "central" point
  var center = {
    lat: minX + (maxX - minX) / 2,
    lng: minY + (maxY - minY) / 2,
  };

  // precalculate the angles of each point to avoid multiple calculations on sort
  for (var i = 0; i < points.length; i++) {
    points[i].angle = Math.acos(
      (points[i].lat - center.lat) / lineDistance(center, points[i])
    );

    if (points[i].lng > center.lng) {
      points[i].angle = Math.PI + Math.PI - points[i].angle;
    }
  }

  // sort by angle
  points = points.sort(function (a, b) {
    return a.angle - b.angle;
  });

  return points;
}
