import React, { useContext, useState } from "react";
import GoogleMapReact from "google-map-react";
import { MapContext } from "../contexts/MapContextProvider";
import StopMarker from "./StopMarker";
import DrawingManager from "./DrawingManager";
import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
import mapDefaultProps from "../configuration/mapDefaultProps";
import AffectationLivreurDialog from "./AffectationDialog";
import LivreurMarker from "./LivreurMarker";
import LivreurService from "../services/LivreurService";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
export default function Map() {
  const { setGoogleMap, mapParams, onMapChange } = useContext(MapContext);
  const {
    stops,
    livreurs,
    setLivreurs,
    selectedVehiculeTypeId,
    invisibleTournees,
  } = useContext(ExpeditionContext);
  const [mapState, setMapState] = useState({
    center: mapParams.center,
    markerCenter: mapParams.center,
    draggable: true,
  });

  const onMouseDown = (childKey, childProps, mouse) => {
    if (childProps.livreurkey) {
      setMapState({
        ...mapState,
        draggable: false,
      });
    }
  };

  const onMouseMove = (childKey, childProps, mouse) => {
    if (childProps.livreurkey) {
      setMapState({
        ...mapState,
        markerCenter: mouse,
      });
      setLivreurs(
        livreurs.map((livreur) =>
          livreur.id == childProps.livreurkey
            ? {
                ...livreur,
                currentLocation: mouse,
              }
            : livreur
        )
      );
    }
  };

  const onMouseUp = (childKey, childProps, mouse) => {
    if (childProps.livreurkey) {
      confirmAlert({
        title: "Attention",
        message: "Êtes-vous sûr de vouloir changer la position du livreur ?",
        buttons: [
          {
            label: "Confirmer",
            onClick: () => {
              LivreurService.saveLivreurNewLocalisation({
                livreurId: childProps.livreurkey,
                lat: mouse.lat,
                lng: mouse.lng,
              })
                .then((response) => {
                  const result = response.data;
                  if (result.success) {
                    setLivreurs(
                      livreurs.map((livreur) =>
                        livreur.id == childProps.livreurkey
                          ? {
                              ...livreur,
                              defaultLocation: livreur.currentLocation,
                            }
                          : livreur
                      )
                    );
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            },
          },
          {
            label: "Annuler",
            onClick: () =>
              setLivreurs(
                livreurs.map((livreur) =>
                  livreur.id == childProps.livreurkey
                    ? {
                        ...livreur,
                        currentLocation: livreur.defaultLocation,
                      }
                    : livreur
                )
              ),
          },
        ],
      });
      setMapState({
        ...mapState,
        markerCenter: mouse,
        center: mouse,
        draggable: true,
      });
    }
  };

  const StopsMarkers =
    stops &&
    stops
      .filter(
        (m) =>
          (!m.livreurId ||
            livreurs.some((l) => l.id == m.livreurId && l.isVisible)) &&
          !invisibleTournees.includes(m.tourneeId)
      )
      .map((stop) => (
        <StopMarker key={stop.id} lat={stop.lat} lng={stop.lng} stop={stop} />
      ));

  const LivreursMarkers =
    livreurs &&
    livreurs
      .filter((m) => m.isVisible && m.vehiculetypeId == selectedVehiculeTypeId)
      .map((livreur) => (
        <LivreurMarker
          draggable={true}
          key={livreur.id}
          lat={livreur.currentLocation.lat}
          lng={livreur.currentLocation.lng}
          livreur={livreur}
          livreurkey={livreur.id}
        />
      ));

  return (
    // Important! Always set the container height explicitly

    <div
      style={{
        height: window.innerHeight - 90,
        width: "100%",
        position: "relative",
        overflow: "none",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: mapDefaultProps.key,
          v: "3.0",
          libraries: mapDefaultProps.libraries,
        }}
        onChange={onMapChange}
        center={mapState.center}
        zoom={mapParams.zoom}
        draggable={mapState.draggable}
        onChildMouseDown={onMouseDown}
        onChildMouseUp={onMouseUp}
        onChildMouseMove={onMouseMove}
        style={{
          mapTypeId: "roadmap",
          fillColor: "#fff",
          fillOpacity: 1,
          height: "100%",
          width: "100%",
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(googlemap) => setGoogleMap(googlemap)}
      >
        <DrawingManager />
        {StopsMarkers}
        {LivreursMarkers}
      </GoogleMapReact>
      <AffectationLivreurDialog />
    </div>
  );
}
