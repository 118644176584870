import http from "./http-common";
const login = async (username, password) => {
  return await http
    .post("/dispatch/login", {
      username,
      password,
    })
    .then((response) => {
      var result = response.data;
      if (result.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(result.data));
      }
      return result.data;
    });
};
const logout = () => {
  localStorage.removeItem("user");
};
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
export default {
  login,
  logout,
  getCurrentUser,
};
