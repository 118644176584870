import React, { createContext, useContext, useState, useEffect } from "react";
import { MapContext } from "./MapContextProvider";

export const OverlayMapContext = createContext();

export const OverlayMapContextProvider = ({ children }) => {
  const supportedOverlayType = {
    Polygon: "polygon",
    Rectangle: "rectangle",
    Circle: "circle",
  };

  const { googleMap } = useContext(MapContext);
  const [overlaylist, setOverlayList] = useState([]);
  const [isRightClickOverlay, setIsRightClickOverlay] = useState(false);
  const [selectedOverlayList, setSelectedOverlayList] = useState([]);
  const [stopPolygonsForDemonstrate, setStopPolygonsForDemonstrate] = useState(
    []
  );

  useEffect(() => {
    if (googleMap) {
      //Add click event for map for disable select event from overlay
      googleMap.map.addListener("click", () => {
        if (selectedOverlayList && selectedOverlayList.length > 0) {
          selectedOverlayList.map((overlay) => {
            overlay.setOptions({
              strokeColor: "#1E90FF",
            });
          });
          setSelectedOverlayList([]);
        }
      });
      //Delete selected overlay list
      googleMap.maps.event.addDomListener(document, "keyup", function (e) {
        var code = e.keyCode ? e.keyCode : e.which;
        if (code === 27 && selectedOverlayList.length > 0) {
          selectedOverlayList.map((overlay) => {
            overlay.setMap(null);
            const newOverlaylist = overlaylist.filter(
              (m) => m.overlay.customid !== overlay.customid
            );
            setOverlayList(newOverlaylist);
          });
        }
      });
    }
  }, [googleMap, selectedOverlayList]);

  //Add right click event to each overlay (polygon,circle,...)
  const addOverlayEvents = (overlay) => {
    overlay.addListener("contextmenu", function (e) {
      setIsRightClickOverlay(true);
    });
    overlay.addListener("click", function (e) {
      overlay.setOptions({
        strokeColor: "red",
      });
      overlay.customid = new Date().getTime();
      setSelectedOverlayList((selectedOverlayList) => [
        ...selectedOverlayList,
        overlay,
      ]);
    });
  };

  const addOverlayObject = (overlay, type) => {
    addOverlayEvents(overlay);
    const element = {
      type: type,
      overlay: overlay,
    };
    setOverlayList((overlaylist) => [...overlaylist, element]);
  };

  const removeAllOverlayObjects = () => {
    overlaylist.map((element) => {
      element.overlay.setMap(null);
    });
    setOverlayList([]);
    setSelectedOverlayList([]);
  };

  const cleanStopsDemonstratePolygonsHandler = () => {
    stopPolygonsForDemonstrate.map((polygon) => {
      polygon.setMap(null);
    });
    setStopPolygonsForDemonstrate([]);
  };

  return (
    <OverlayMapContext.Provider
      value={{
        overlaylist,
        addOverlayObject,
        isRightClickOverlay,
        setIsRightClickOverlay,
        supportedOverlayType,
        removeAllOverlayObjects,
        stopPolygonsForDemonstrate,
        setStopPolygonsForDemonstrate,
        cleanStopsDemonstratePolygonsHandler,
      }}
    >
      {children}
    </OverlayMapContext.Provider>
  );
};
