import http from "./http-common";
import authHeader from "../services/AuthHeader";
const getAll = async () => {
  return await http.get("/GetListExpeditions", { headers: authHeader() });
};

const generateExpeditionForAdjournedAndReturn = async () => {
  return await http.get("/GenerateForAjournAndRetourStops", {
    headers: authHeader(),
  });
};

const createPlanification = async (data) => {
  console.log(data);
  return await http.post("/SaveExpedition", data, { headers: authHeader() });
};

export default {
  getAll,
  generateExpeditionForAdjournedAndReturn,
  createPlanification,
};
