import { createContext, useState, useContext } from "react";
import ExpeditionService from "../services/ExpeditionService";
import DispatchProgressService from "../services/DispatchProgressService";
import VehiculeTypeService from "../services/VehiculeTypeService";
import AvailableDateService from "../services/AvailableDateService";
import LivreurService from "../services/LivreurService";
import StopService from "../services/StopService";
import TourneeService from "../services/TourneeService";
import { OverlayMapContext } from "./OverlayMapContextProvider";
import { MapContext } from "./MapContextProvider";
export const ExpeditionContext = createContext();

export const ExpeditionContextProvider = ({ children }) => {
  const [savedDispatchData, setSavedDispatchData] = useState();
  const [expeditions, setExpeditions] = useState([]);
  const [selectedExpeditionIds, setSelectedExpeditionIds] = useState([]);

  const [stops, setStops] = useState([]);
  const [selectedStopInMapInfos, setselectedStopInMapInfos] = useState();

  const [livreurs, setLivreurs] = useState([]);
  const [selectedLivreurForAffectation, setSelectLivreurForAffectation] =
    useState();

  const [selectedTourneeId, setSelectTourneeId] = useState();
  const [selectedLivreurInMapInfo, setSelectedLivreurInMapInfo] = useState();
  const [invisibleTournees, setInvisibleTournees] = useState([]);
  const [vehiculeTypes, setVehiculeTypes] = useState([]);
  const [selectedVehiculeTypeId, setSelectedVehiculeTypeId] = useState();

  //AvailableDate
  const [AvailableDate, setAvailableDate] = useState("");

  const { googleMap } = useContext(MapContext);
  const { overlaylist, supportedOverlayType, removeAllOverlayObjects } =
    useContext(OverlayMapContext);

  const getSelectedStopsIds = () => {
    let stopsIds = [];
    const livreursNonVisible = livreurs
      .filter((m) => !m.isVisible)
      .map((elm) => elm.id);
    overlaylist.map((element) => {
      switch (element.type) {
        case supportedOverlayType.Polygon:
          stops.map((stop) => {
            if (
              stop.isVisible &&
              !livreursNonVisible.includes(stop.livreurId) &&
              !invisibleTournees.includes(stop.tourneeId)
            ) {
              var position = new googleMap.maps.LatLng(stop.lat, stop.lng);
              if (
                googleMap.maps.geometry.poly.containsLocation(
                  position,
                  element.overlay
                )
              ) {
                if (!stopsIds.includes(stop.id)) stopsIds.push(stop.id);
              }
            }
          });
          break;
        case supportedOverlayType.Rectangle:
          stops.map((stop) => {
            if (
              stop.isVisible &&
              !livreursNonVisible.includes(stop.livreurId) &&
              !invisibleTournees.includes(stop.tourneeId)
            ) {
              var position = new googleMap.maps.LatLng(stop.lat, stop.lng);
              if (element.overlay.getBounds().contains(position)) {
                if (!stopsIds.includes(stop.id)) stopsIds.push(stop.id);
              }
            }
          });
          break;
        case supportedOverlayType.Circle:
          stops.map((stop) => {
            if (
              stop.isVisible &&
              !livreursNonVisible.includes(stop.livreurId) &&
              !invisibleTournees.includes(stop.tourneeId)
            ) {
              var position = new googleMap.maps.LatLng(stop.lat, stop.lng);
              if (element.overlay.getBounds().contains(position)) {
                if (!stopsIds.includes(stop.id)) stopsIds.push(stop.id);
              }
            }
          });
          break;
        default:
          break;
      }
    });
    return stopsIds;
  };

  const affectationStopsToLivreurFromSelectedOverleysHandler = () => {
    //Affectation Livreur
    if (selectedLivreurForAffectation) {
      const selectedStopsIdsList = getSelectedStopsIds();
      setStops(
        stops.map((stop) =>
          selectedStopsIdsList.includes(stop.id)
            ? {
                ...stop,
                livreurId: selectedLivreurForAffectation.id,
                color: selectedLivreurForAffectation.color,
                tourneeId: selectedTourneeId,
                dateAjourne: null,
                isDone: true,
                isVisible: true,
              }
            : stop
        )
      );
      removeAllOverlayObjects();
      deleteselectedLivreurForAffectationHandler();
    }
  };

  const affectationStopsToAjournementFromSelectedOverleysHandler = () => {
    var selectedStopsIdsList = getSelectedStopsIds();
    //Affectation Livreur
    var dateParts = AvailableDate.split("/");
    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    setStops(
      stops.map((stop) =>
        selectedStopsIdsList.includes(stop.id)
          ? {
              ...stop,
              livreurId: null,
              color: "red",
              tourneeId: null,
              dateAjourne: dateObject,
              isDone: true,
            }
          : stop
      )
    );
    removeAllOverlayObjects();
    deleteselectedLivreurForAffectationHandler();
  };

  const transferTourneeVersNouveauLivreurHandler = (
    fromLivreurId,
    fromTourneeId,
    toLivreur,
    toTourneeId
  ) => {
    setStops(
      stops.map((stop) =>
        stop.livreurId == fromLivreurId && stop.tourneeId == fromTourneeId
          ? {
              ...stop,
              livreurId: toLivreur.id,
              color: toLivreur.color,
              tourneeId: toTourneeId,
              dateAjourne: null,
              isDone: true,
            }
          : stop
      )
    );
  };

  const transferTourneeToLivreurWithNewTourneeHandler = (
    fromlivreurId,
    fromTourneeId,
    toLivreur
  ) => {
    addTourneeToLivreurByIdHandler(toLivreur.id, (newTourneeId) => {
      setStops(
        stops.map((stop) =>
          stop.livreurId == fromlivreurId && stop.tourneeId == fromTourneeId
            ? {
                ...stop,
                livreurId: toLivreur.id,
                color: toLivreur.color,
                tourneeId: newTourneeId,
                dateAjourne: null,
                isDone: true,
              }
            : stop
        )
      );
    });
  };

  const getAllExpeditions = () => {
    ExpeditionService.getAll()
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setExpeditions(result.data);
          setSelectedExpeditionIds([]);
          setStops([]);
          setInvisibleTournees([]);
          if (savedDispatchData) {
            let newSelectedExpedtionsFromLocalData = [];
            savedDispatchData.map((stop) => {
              if (
                result.data.includes(stop.expeditionId) &&
                !newSelectedExpedtionsFromLocalData.includes(stop.expeditionId)
              )
                newSelectedExpedtionsFromLocalData.push(stop.expeditionId);
            });
            if (newSelectedExpedtionsFromLocalData.length > 0)
              setSelectedExpeditionIds(newSelectedExpedtionsFromLocalData);
            getAllStopsByExpedition(newSelectedExpedtionsFromLocalData);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllStopsByExpedition = (ExpeditionIds) => {
    StopService.getAll(ExpeditionIds)
      .then((response) => {
        const result = response.data;
        if (result.success) {
          if (savedDispatchData) {
            let newStopsFromLocalData = [];
            result.data.map((stop) => {
              const localStop = savedDispatchData.find((m) => m.id == stop.id);
              if (localStop) {
                newStopsFromLocalData.push(localStop);
              } else newStopsFromLocalData.push(stop);
            });
            setStops(newStopsFromLocalData);
          } else {
            setStops(result.data);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const tryToGenerateExpeditionForAdjourneeAndReturnHandler = (callback) => {
    ExpeditionService.generateExpeditionForAdjournedAndReturn()
      .then((response) => {
        const result = response.data;
        if (result.success && result.data) {
          setExpeditions([...expeditions, result.data]);
          callback(true);
        } else {
          callback(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllLivreurs = () => {
    var listLivreurIdWithTournees = [];
    if (savedDispatchData)
      savedDispatchData
        .filter((m) => m.isDone && m.tourneeId && m.livreurId)
        .map((stop) => {
          if (!listLivreurIdWithTournees.includes(stop.livreurId)) {
            listLivreurIdWithTournees.push(stop.livreurId);
          }
        });
    LivreurService.getAll(listLivreurIdWithTournees)
      .then((response) => {
        const result = response.data;
        if (result.success) {
          if (savedDispatchData) {
            const newLivreurList = [];
            result.data.map((livreur) => {
              savedDispatchData.map((stop) => {
                if (
                  livreur.id == stop.livreurId &&
                  !livreur.tournees.includes(stop.tourneeId)
                )
                  livreur.tournees.push(stop.tourneeId);
              });
              newLivreurList.push(livreur);
            });
            setLivreurs(newLivreurList);
          } else {
            setLivreurs(result.data);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllVehiculeTypes = () => {
    VehiculeTypeService.getAll()
      .then((response) => {
        const result = response.data;
        if (result.success) setVehiculeTypes(result.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAvailableDate = () => {
    AvailableDateService.getAll()
      .then((response) => {
        const result = response.data;
        if (result.success) setAvailableDate(result.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createPlanificationHandler = (successCallback) => {
    if (stops.filter((m) => !m.isDone).length == 0) {
      ExpeditionService.createPlanification(stops)
        .then((response) => {
          const result = response.data;
          if (result.success) {
            setSavedDispatchData(null);
            setLivreurs(
              livreurs.map((livreur) => ({
                ...livreur,
                tournees: [],
              }))
            );
            getAllExpeditions();
            successCallback();
            return true;
          }
          return false;
        })
        .catch((e) => {
          return false;
        });
    }
  };

  const changeSelectedExpeditionIdsHandler = (values) => {
    getAllStopsByExpedition(values.filter((m) => m !== ""));
    setSelectedExpeditionIds(values.filter((m) => m !== ""));
  };

  const changeselectedLivreurForAffectationHandler = (livreurid) => {
    setSelectTourneeId(null);
    var livreur = livreurs.find((m) => m.id == livreurid);
    if (livreur) setSelectLivreurForAffectation(livreur);
    else {
      setSelectLivreurForAffectation(null);
      setSelectTourneeId(null);
    }
  };

  const changeselectedTourneeIdHandler = (tourneeId) => {
    if (selectedLivreurForAffectation) setSelectTourneeId(tourneeId);
    else setSelectTourneeId(null);
  };

  const deleteselectedLivreurForAffectationHandler = () => {
    setSelectLivreurForAffectation(null);
    setSelectTourneeId(null);
  };

  const changeSelectedVehiculeTypeIdHandler = (value) => {
    if (value && value != selectedVehiculeTypeId) {
      setSelectedVehiculeTypeId(value);
    }
  };

  const changeLivreurVisibliteHandler = (selectedLivreur, isVisible) => {
    setLivreurs(
      livreurs.map((livreur) =>
        livreur.id == selectedLivreur.id
          ? {
              ...livreur,
              isVisible: isVisible,
            }
          : livreur
      )
    );
  };

  const addTourneeToLivreurByIdHandler = (livreurId, callback = null) => {
    TourneeService.getNewTourneeId()
      .then((response) => {
        const result = response.data;
        if (result.success) {
          var newTourneeId = result.data;
          setLivreurs(
            livreurs.map((item) =>
              item.id === livreurId
                ? {
                    ...item,
                    tournees: [...item.tournees, newTourneeId],
                  }
                : item
            )
          );
          if (callback) {
            callback(newTourneeId);
          }
          return newTourneeId;
        }
      })
      .catch((e) => {
        return null;
      });
  };

  const removeTourneeToLivreurByIdHandler = (livreurId, tourneeId) => {
    setLivreurs(
      livreurs.map((item) =>
        item.id === livreurId
          ? {
              ...item,
              tournees: item.tournees.filter((t) => t != tourneeId),
            }
          : item
      )
    );

    setStops(
      stops.map((stop) =>
        stop.tourneeId == tourneeId
          ? {
              ...stop,
              tourneeId: null,
              isDone: false,
              livreurId: null,
              color: "#000",
            }
          : stop
      )
    );

    if (invisibleTournees.includes(tourneeId))
      setInvisibleTournees(invisibleTournees.filter((m) => m != tourneeId));
  };
  const saveStopsDataToLocalStorageHandler = (callback) => {
    if (stops && stops.some((m) => m.isDone))
      DispatchProgressService.saveDispatchProgress({
        JsonData: JSON.stringify(stops),
      })
        .then((response) => {
          const result = response.data;
          if (result.success) {
            callback();
            return true;
          }
        })
        .catch((e) => {
          return false;
        });
  };

  return (
    <ExpeditionContext.Provider
      value={{
        expeditions,
        stops,
        setStops,
        selectedStopInMapInfos,
        setselectedStopInMapInfos,
        livreurs,
        setLivreurs,
        selectedLivreurInMapInfo,
        setSelectedLivreurInMapInfo,
        vehiculeTypes,
        setVehiculeTypes,
        AvailableDate,
        setAvailableDate,
        selectedLivreurForAffectation,
        selectedTourneeId,
        changeselectedLivreurForAffectationHandler,
        deleteselectedLivreurForAffectationHandler,
        changeselectedTourneeIdHandler,
        selectedVehiculeTypeId,
        changeSelectedVehiculeTypeIdHandler,
        addTourneeToLivreurByIdHandler,
        removeTourneeToLivreurByIdHandler,
        selectedExpeditionIds,
        changeSelectedExpeditionIdsHandler,
        createPlanificationHandler,
        setSelectLivreurForAffectation,
        setSelectTourneeId,
        getSelectedStopsIds,
        affectationStopsToLivreurFromSelectedOverleysHandler,
        affectationStopsToAjournementFromSelectedOverleysHandler,
        transferTourneeVersNouveauLivreurHandler,
        transferTourneeToLivreurWithNewTourneeHandler,
        changeLivreurVisibliteHandler,
        saveStopsDataToLocalStorageHandler,
        getAllExpeditions,
        getAllLivreurs,
        getAllVehiculeTypes,
        getAvailableDate,
        setSelectedVehiculeTypeId,
        invisibleTournees,
        setInvisibleTournees,
        setSavedDispatchData,
        savedDispatchData,
        tryToGenerateExpeditionForAdjourneeAndReturnHandler,
      }}
    >
      {children}
    </ExpeditionContext.Provider>
  );
};
