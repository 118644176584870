import React, { useContext } from "react";
import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
import { MapContext } from "../contexts/MapContextProvider";
export default function LivreurMarker({ livreur }) {
  const { mapParams } = useContext(MapContext);
  const { setSelectedLivreurInMapInfo } = useContext(ExpeditionContext);
  const clickEvent = () => {
    setSelectedLivreurInMapInfo(livreur);
  };
  const markerSize = mapParams.zoom + 20;
  return (
    <img
      onClick={clickEvent}
      src={livreur.image}
      alt={livreur.fullname}
      height={markerSize}
      width={markerSize}
      style={{
        position: "absolute",
        transform: "translate(-50%, -50%)",
        borderRadius: "50%",
        zIndex: "1000",
        padding: "0px",
        border: "2px solid " + livreur.color,
      }}
    />
  );
}
