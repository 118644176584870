import React, {useEffect,useContext} from "react";
import RightDrawerLivreurList from "../components/RightDrawerLivreurList";
import Map from "../components/Map";
import StopInfoDialog from "../components/StopInfoDialog";
import TopAppBar from "../components/TopAppBar";
import StatistiqueAffectationBar from "../components/StatistiqueAffectationBar";
import LivreurInfoDialog from "../components/LivreurInfoDialog";
import TourneesPolygonsButton from "../components/TourneesPolygonsButton";
import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
import DispatchProgressService from "../services/DispatchProgressService";
export default function Home() {

  const {
    getAllExpeditions,
    getAllLivreurs,
    getAllVehiculeTypes,
    getAvailableDate,
    setSelectedVehiculeTypeId,
    setSavedDispatchData,
    savedDispatchData
  } 
  = useContext(ExpeditionContext)

    useEffect(() => {
              DispatchProgressService.getDispatchProgress()
            .then((response) => {
              var result = response.data;
              if (result.success) {
                var savedlocalStops = JSON.parse(result.data.jsonData);
                setSavedDispatchData(savedlocalStops)
              }else{
                setSavedDispatchData([])
              }
              })
              .catch((e) => {
                console.log(e);
              })
  }, []);

  useEffect(()=>{
          if(savedDispatchData){
                getAllExpeditions();
                getAllLivreurs();
                getAllVehiculeTypes();
                getAvailableDate();
                setSelectedVehiculeTypeId(1);
          }
  },[savedDispatchData])

  return (
    <div style={{ height: "100%", width: "100%", overflow: "none" }}>
      <StopInfoDialog />
      <TourneesPolygonsButton />
      <LivreurInfoDialog />
      <TopAppBar />
      <StatistiqueAffectationBar />
      <Map />     
      <RightDrawerLivreurList />
    </div>
  );
}
