import { useState, useContext } from "react";
import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
import RightDrawerLivreurItem from "./RightDrawerLivreurItem";
import VehiculeTypeSwitchButtons from "./VehiculeTypeSwitchButtons";
import LivreurSearchBar from "./LivreurSearchBar";
import Drawer from "./Drawer";
import "../css/RightDrawerLivreurList.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
export default function RightDrawerLivreurList() {
  const {
    livreurs,
    selectedVehiculeTypeId,
    createPlanificationHandler,
    saveStopsDataToLocalStorageHandler,
  } = useContext(ExpeditionContext);

  const [showLivreurIsVisible, setShowLivreurIsVisible] = useState(true);
  const [searchLivreurValue, setSearchLivreurValue] = useState("");

  const LivreurList = livreurs
    .filter(
      (m) =>
        m.vehiculetypeId == selectedVehiculeTypeId &&
        (showLivreurIsVisible === "false" ? m.isVisible : true) &&
        (searchLivreurValue
          ? m.fullname.toLowerCase().includes(searchLivreurValue.toLowerCase())
          : true)
    )
    .map((livreur) => (
      <RightDrawerLivreurItem key={livreur.id} livreur={livreur} />
    ));
  const messageCreatePlanificatioInfo = () => {
    confirmAlert({
      title: "Information",
      message: "La simulation a été verrouillé avec succès",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            setIsOpen(!isOpen);
            window.location.reload();
          },
        },
      ],
    });
  };
  const messageNotAllowToCreatePlanification = () => {
    confirmAlert({
      title: "Alerte",
      message:
        "Veuillez affecter tous les stops afin de verrouiller le dispatching",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            setIsOpen(!isOpen);
          },
        },
      ],
    });
  };
  const createPlanificationEvent = () => {
    confirmAlert({
      title: "Confirmer",
      message: "Êtes-vous sûr de vouloir Verrouiller le dispatching.",
      buttons: [
        {
          label: "Confirmer",
          onClick: () =>
            createPlanificationHandler(messageCreatePlanificatioInfo),
        },
        {
          label: "Annuler",
          onClick: () => console.log("Save cancelled."),
        },
      ],
    });
  };

  const messageSaveInfo = () => {
    confirmAlert({
      title: "Information",
      message: "Vos changements sont été enregistrée avec succès",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            setIsOpen(!isOpen);
          },
        },
      ],
    });
  };

  const saveStopsDataToLocalStorageEvent = async () => {
    confirmAlert({
      title: "Confirmer",
      message: "Êtes-vous sûr de vouloir sauvegarder votre travail on local",
      buttons: [
        {
          label: "Confirmer",
          onClick: () => {
            saveStopsDataToLocalStorageHandler(messageSaveInfo);
          },
        },
        {
          label: "Annuler",
          onClick: () => console.log("Deletion cancelled."),
        },
      ],
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        className="drawer-btn"
        onClick={() => setIsOpen(!isOpen)}
      >
        Dispatch
      </button>
      <Drawer
        id="livreurs-drawer"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        position="right"
      >
        <div className="demo-content">
          <button
            type="button"
            className="drawer-close-btn"
            onClick={() => setIsOpen(false)}
          >
            <i className="las la-times  la-x2"></i>
          </button>
          <VehiculeTypeSwitchButtons
            setShowLivreurIsVisible={setShowLivreurIsVisible}
            setSearchLivreurValue={setSearchLivreurValue}
          />
        </div>
        <LivreurSearchBar
          showLivreurIsVisible={showLivreurIsVisible}
          setShowLivreurIsVisible={setShowLivreurIsVisible}
          searchLivreurValue={searchLivreurValue}
          setSearchLivreurValue={setSearchLivreurValue}
        />
        <hr />
        {LivreurList}

        <div
          className="verrouiller-planification-btn"
          onClick={createPlanificationEvent}
        >
          <i className="las la-lock-open cursor-pointer mt-auto"></i>
          Verrouiller le dispatching
        </div>
        <button
          className="save-planification-btn"
          onClick={saveStopsDataToLocalStorageEvent}
        >
          Sauvegarder
        </button>
      </Drawer>
    </>
  );
}
