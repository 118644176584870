import http from "./http-common";
import authHeader from "../services/AuthHeader";
const getDispatchProgress = async () => {
  console.log(authHeader());
  return await http.get("/GetDispatchProgress", {
    headers: authHeader(),
  });
};

const saveDispatchProgress = async (data) => {
  return await http.post("/SaveDispatchProgress", data, {
    headers: authHeader(),
  });
};
export default {
  getDispatchProgress,
  saveDispatchProgress,
};
