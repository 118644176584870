import React, { useContext } from 'react'
import "../css/VehiculeTypeSwitchButtons.css"
import { ExpeditionContext } from '../contexts/ExpeditionContextProvider';
 
export default function VehiculeTypeSwitchButtons() {
   const {
    vehiculeTypes,
    selectedVehiculeTypeId,
    changeSelectedVehiculeTypeIdHandler,
  } = useContext(ExpeditionContext);
  const buttonWidth = 100/vehiculeTypes.length
  const clickEvent = (vehiculeId) =>{
    changeSelectedVehiculeTypeIdHandler(vehiculeId)
  }
  return (
    <div className='type-vehicule-group-btns'>
      {vehiculeTypes.map((vehiculeType) => (
        <button

          key={vehiculeType.id}
          className={selectedVehiculeTypeId == vehiculeType.id ? "active-btn" : "btn"}
          style={{width : buttonWidth + "%"}}
          onClick={() => clickEvent(vehiculeType.id)}
        >
          {vehiculeType.libelle}
        </button>
      ))}</div>
  )
}
