import React, { useContext, useEffect, useState } from 'react'
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { styled } from '@mui/system';
import { ExpeditionContext } from '../contexts/ExpeditionContextProvider';
import { MapContext } from '../contexts/MapContextProvider';
import {groupBy} from "../Helpers/ArrayHelpers"
import {getSortPolygonPoints} from "../Helpers/DrawingHelpers"
import { OverlayMapContext } from '../contexts/OverlayMapContextProvider';
const TourneesPolygonsButtonDivStyled = styled('div')(()=>`
    background: none padding-box #fff;
    display: block;
    border: 0px;
    margin: 0px;
    padding: 8px;
    text-transform: none;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    direction: ltr;
    overflow: hidden;
    text-align: left;
    color: #565656;
    font-family: Roboto, Arial, sans-serif;
    font-size: 11px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    z-index: 10;
    top: 241px;
    left: 8px;
`)
export default function TourneesPolygonsButton() {
  const {stops,invisibleTournees} = useContext(ExpeditionContext);
  const {googleMap} = useContext(MapContext)
  const [isActive,setIsActive] = useState(false);
  const {stopPolygonsForDemonstrate,setStopPolygonsForDemonstrate,cleanStopsDemonstratePolygonsHandler} = useContext(OverlayMapContext)


  useEffect(() => {
    if(stopPolygonsForDemonstrate.length > 0){
      setIsActive(true)
    }else{
      setIsActive(false)
    }
  },[stopPolygonsForDemonstrate]);

  const clickEvent = () =>{
    cleanStopsDemonstratePolygonsHandler()
    if(isActive){
      setIsActive(false)
    }else if(stops.some(m => m.isVisible && m.isDone &&  m.livreurId && m.tourneeId)){

      setIsActive(true)
      if(stops && stops.length > 0){
        const newStopsPolygons = [];
        var stopsGroupByLivreurId = groupBy(stops.filter(m => m.isDone && m.livreurId && m.tourneeId), "livreurId");
        if(stopsGroupByLivreurId){
          const stopsGroupByTourneeId = groupBy(stopsGroupByLivreurId,"tourneeId");
          if(stopsGroupByTourneeId){
             stopsGroupByTourneeId.map((livreurTournees) => {
                livreurTournees
                .map((tournees) => {
                  if(tournees.some( m => invisibleTournees.includes(m.tourneeId))){
                    return false
                  }
                  const coords = tournees
                  .map(stop => ({lat:stop.lat,lng:stop.lng}))
                  const color = tournees[0].color;
                  const newPolygon = new googleMap.maps.Polygon({
                        paths: getSortPolygonPoints(coords),
                        strokeColor: color,
                        strokeOpacity: 0.6,
                        strokeWeight: 1,
                        fillColor: color,
                  });

                  newPolygon.setMap(googleMap.map);
                  newStopsPolygons.push(newPolygon);
                
              })
            })
          }
          setStopPolygonsForDemonstrate(newStopsPolygons)
        }
      }
    }
    
  }
  return (
    <TourneesPolygonsButtonDivStyled onClick={clickEvent}>
        <BookmarksIcon style={{fontSize:"16px" ,color: isActive ? "#565656":"#a0a0a0" }} />
    </TourneesPolygonsButtonDivStyled>
  )
}
