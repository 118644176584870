import { useState, useContext } from "react";
import Select from "react-select";
import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
import { OverlayMapContext } from "../contexts/OverlayMapContextProvider";
import "../css/StatistiqueAffectationBar.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

function StatistiqueItem({ title, count, color, checkEvent, checked }) {
  return (
    <div className="statistique-element" style={{ borderColor: color }}>
      <input
        className="statistique-checkbox"
        onChange={checkEvent}
        type="checkbox"
        checked={checked}
        style={{ accentColor: color }}
      />
      <span style={{ color: color }}>{title}</span>
      <span className="statistique-count" style={{ background: color }}>
        {count}
      </span>
    </div>
  );
}

export default function StatistiqueAffectationBar() {
  const {
    stops,
    setStops,
    expeditions,
    changeSelectedExpeditionIdsHandler,
    selectedExpeditionIds,
    tryToGenerateExpeditionForAdjourneeAndReturnHandler,
  } = useContext(ExpeditionContext);
  const { cleanStopsDemonstratePolygonsHandler } =
    useContext(OverlayMapContext);

  const totalStops = stops.length;

  const totalStopsAffectees = stops.filter(
    (m) => m.isDone && m.livreurId
  ).length;

  const [showTotalStopsAffecteesChecked, setShowTotalStopsAffecteesChecked] =
    useState(true);
  const ShowTotalStopsAffecteesCheckedChangeEvent = (event) => {
    const showVal = event.target.checked;
    setShowTotalStopsAffecteesChecked(showVal);
    setStops(
      stops.map((stop) =>
        stop.isDone && stop.livreurId
          ? {
              ...stop,
              isVisible: showVal,
            }
          : stop
      )
    );
    if (!showVal) {
      cleanStopsDemonstratePolygonsHandler();
    }
  };
  const totalStopsNonAffectees = stops.filter((m) => !m.isDone).length;

  const [
    showTotalStopsNonAffecteesChecked,
    setShowTotalStopsNonAffecteesChecked,
  ] = useState(true);
  const showTotalStopsNonAffecteesCheckedChangeEvent = (event) => {
    var showVal = event.target.checked;
    setShowTotalStopsNonAffecteesChecked(showVal);
    let newStopsList = [];
    stops.map((stop) => {
      if (!stop.isDone) {
        stop.isVisible = showVal;
      }
      newStopsList = [...newStopsList, stop];
    });
    setStops(newStopsList);
  };
  const totalStopsReportes = stops.filter(
    (m) => m.isDone && m.dateAjourne
  ).length;

  const [showTotalStopsReportesChecked, setShowTotalStopsReportesChecked] =
    useState(true);
  const showTotalStopsReportesCheckedChangeEvent = (event) => {
    var showVal = event.target.checked;
    setShowTotalStopsReportesChecked(showVal);
    let newStopsList = [];
    stops.map((stop) => {
      if (stop.isDone && stop.dateAjourne) {
        stop.isVisible = showVal;
      }
      newStopsList = [...newStopsList, stop];
    });
    setStops(newStopsList);
  };

  const ShowGenerateExpeditionMessage = (success) => {
    if (success) {
      confirmAlert({
        title: "Confirmation",
        message: "L'expédition a été généré avec succès",
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Information",
        message: "Tous les stops et les retours sont déjà générées",
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
          },
        ],
      });
    }
  };
  const GenerateExpeditionAjournementEtRetourEvent = () => {
    tryToGenerateExpeditionForAdjourneeAndReturnHandler(
      ShowGenerateExpeditionMessage
    );
  };
  const changeExpeditionEvent = (newValues) => {
    const values = newValues.map((v) => v.value);
    changeSelectedExpeditionIdsHandler(values);
  };
  const expeditionDefaultValues = selectedExpeditionIds.map((ele) => ({
    label: ele,
    value: ele,
  }));
  return (
    <div className="container d-flex justify-content-between align-items-center grey-bg">
      <div className="d-flex justify-content-between align-items-center">
        <div className="font-weight-bold"> Total stops: {totalStops}</div>
        <StatistiqueItem
          title="Affectés"
          checkEvent={ShowTotalStopsAffecteesCheckedChangeEvent}
          count={totalStopsAffectees}
          checked={showTotalStopsAffecteesChecked}
          color="#1BC5BD"
        />
        <StatistiqueItem
          title="Non affectés"
          checkEvent={showTotalStopsNonAffecteesCheckedChangeEvent}
          count={totalStopsNonAffectees}
          checked={showTotalStopsNonAffecteesChecked}
          color="#F64E60"
        />
        <StatistiqueItem
          title="Reportés"
          checkEvent={showTotalStopsReportesCheckedChangeEvent}
          count={totalStopsReportes}
          checked={showTotalStopsReportesChecked}
          color="#FFA800"
        />
      </div>
      <div className="d-flex">
        <button
          className="btn-success"
          onClick={GenerateExpeditionAjournementEtRetourEvent}
        >
          Générer ajournements et retours
        </button>
        <Select
          isMulti
          placeholder="Sélectionner Expédition"
          options={expeditions.map((e) => ({ value: e, label: e }))}
          closeMenuOnSelect
          value={expeditionDefaultValues}
          onChange={changeExpeditionEvent}
        />
      </div>
    </div>
  );
}
