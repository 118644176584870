import React, { useContext } from "react";
import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
import { MapContext } from "../contexts/MapContextProvider";
export default function StopMarker({ stop }) {
  const { setselectedStopInMapInfos } = useContext(ExpeditionContext);
  const { mapParams } = useContext(MapContext);
  const clickEvent = () => {
    setselectedStopInMapInfos(stop);
  };
  const sizeOffset = stop.dateAjourne ? 2 : 3;
  const stopMarkerSize = mapParams.zoom - sizeOffset;
  const stopMarkerSizeInPixel = stopMarkerSize + "px";

  let border = "none";
  if (stop.dateAjourne) {
    border = "3px solid black";
  } else if (!stop.isDone && stop.isAdjournment) {
    border = "3px solid blue";
  }

  return (
    <div
      onClick={clickEvent}
      style={{
        position: "absolute",
        transform: "translate(-50%, -50%)",
        margin: "0px",
        width: stopMarkerSizeInPixel,
        height: stopMarkerSizeInPixel,
        background: stop.color,
        border: border,
        borderRadius: stop.stopType == 1 ? "50%" : "none",
        display: stop.isVisible ? "block" : "none",
        zIndex: "1100",
      }}
    ></div>
  );
}
