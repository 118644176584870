import React, { useContext } from "react";
import Modal from "react-modal";
import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

export default function StopInfoDialog() {
  const [open, setOpen] = React.useState(false);

  const { selectedStopInMapInfos, setselectedStopInMapInfos } =
    useContext(ExpeditionContext);

  const openEvent = () => {
    setOpen(true);
  };

  const closeEvent = () => {
    setselectedStopInMapInfos(null);
    setOpen(false);
  };

  React.useEffect(() => {
    if (selectedStopInMapInfos) openEvent();
    else closeEvent();
  }, [selectedStopInMapInfos]);

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeEvent}
      style={customStyles}
      contentLabel="Stop information"
    >
      <h4>Stop information</h4>
      <hr />
      <div className="mt-5">
        <span className="font-weight-bold">Id de stop :</span>{" "}
        {selectedStopInMapInfos && selectedStopInMapInfos.id}
      </div>
      <div className="mt-5">
        <span className="font-weight-bold">Nombre colis :</span>{" "}
        {selectedStopInMapInfos &&
          selectedStopInMapInfos.colis &&
          selectedStopInMapInfos.colis.length}
      </div>
      <div className="mt-5">
        <span className="font-weight-bold">Affecté :</span>{" "}
        {selectedStopInMapInfos && selectedStopInMapInfos.isDone
          ? "Oui"
          : "Non"}
      </div>
      <button className="cancel-btn" onClick={closeEvent}>
        Fermer
      </button>
    </Modal>
  );
}
