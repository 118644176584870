import { useContext } from "react";

import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
import "../css/AjournementFormTabItem.css";
export default function AjournementFormTabItem({ closeEvent }) {
  const {
    stops,
    affectationStopsToAjournementFromSelectedOverleysHandler,
    getSelectedStopsIds,
    AvailableDate,
  } = useContext(ExpeditionContext);

  const GetTotalPrixStops = () => {
    var selectedStopsIdsList = getSelectedStopsIds();
    let sum = 0;
    stops
      .filter((m) => selectedStopsIdsList.includes(m.id) && m.stopType == 1)
      .map((stop) => {
        sum += stop.colis.reduce((a, v) => a + v.prix, 0);
      });
    return parseFloat(sum).toFixed(2);
  };

  const affecterEvent = () => {
    affectationStopsToAjournementFromSelectedOverleysHandler();
    closeEvent();
  };
  return (
    <>
      <div>
        <span className="font-weight-bold">Stops</span> :{" "}
        {getSelectedStopsIds().length}
      </div>
      <hr style={{ marginBottom: "5px", marginTop: "5px" }} />
      <div>
        <span className="font-weight-bold">Total Cmd</span> :{" "}
        {GetTotalPrixStops()} Dh
      </div>
      <hr style={{ marginBottom: "15px", marginTop: "5px" }} />
      <input
        className="w-100 mb-15 date-report-input"
        value={AvailableDate}
        readOnly
        disabled
      />
      <div>
        <button onClick={closeEvent} className="cancel-btn">
          Annuler
        </button>
        <button
          disabled={getSelectedStopsIds().length == 0}
          onClick={affecterEvent}
          className="validate-btn"
        >
          Reporter
        </button>
      </div>
    </>
  );
}
