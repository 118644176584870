import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
import { Divider, styled } from "@mui/material";
import Modal from "react-modal";

const FlexJustifyBetweenDivStyled = styled("div")(
  ({ theme }) => `
  display:flex;
  justify-content: space-between;
  align-items:center;
  margin-bottom:5px;
`
);

const BoldDivStyled = styled("div")(
  ({ theme }) => `
  font-weight:bold;
`
);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function LivreurInfoDialog() {
  const [open, setOpen] = useState(false);
  const [livreur, setLivreur] = useState();
  const [stopsCount, setStopsCount] = useState(0);
  const { stops, selectedLivreurInMapInfo, setSelectedLivreurInMapInfo } =
    useContext(ExpeditionContext);

  const openEvent = () => {
    setOpen(true);
  };

  const closeEvent = () => {
    setSelectedLivreurInMapInfo(null);
    setLivreur(null);
    setStopsCount(0);
    setOpen(false);
  };

  useEffect(() => {
    if (selectedLivreurInMapInfo) {
      setLivreur(selectedLivreurInMapInfo);
      setStopsCount(
        stops.filter(
          (m) => m.isDone && m.livreurId == selectedLivreurInMapInfo.id
        ).length
      );
      console.table(stops);
      openEvent();
    } else closeEvent();
  }, [selectedLivreurInMapInfo]);

  Modal.setAppElement("#root");

  const DialogDispaly = livreur ? (
    <Modal isOpen={open} onRequestClose={closeEvent} style={customStyles}>
      <DialogContent>
        <FlexJustifyBetweenDivStyled>
          <img
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
            }}
            src={livreur.image}
            alt={livreur.fullname}
          />
          <div style={{ marginLeft: "30px" }}>
            <h4 style={{ margin: "0 0 4px 0" }}>{livreur.fullname}</h4>
            <div>{livreur.vehiculeLibelle}</div>
            <div>{livreur.vehiculeTypeLibelle}</div>
          </div>
        </FlexJustifyBetweenDivStyled>
        <Divider style={{ marginBottom: "10px" }} />
        <FlexJustifyBetweenDivStyled>
          <BoldDivStyled>Email:</BoldDivStyled>
          <div>{livreur.email}</div>
        </FlexJustifyBetweenDivStyled>

        <FlexJustifyBetweenDivStyled>
          <BoldDivStyled>Téléphone:</BoldDivStyled>
          <div>{livreur.telephone}</div>
        </FlexJustifyBetweenDivStyled>

        <FlexJustifyBetweenDivStyled>
          <BoldDivStyled>Nombre des tournées:</BoldDivStyled>
          <div>{livreur.tournees.length}</div>
        </FlexJustifyBetweenDivStyled>

        <FlexJustifyBetweenDivStyled>
          <BoldDivStyled>Nombre des stops:</BoldDivStyled>
          <div>{stopsCount}</div>
        </FlexJustifyBetweenDivStyled>
      </DialogContent>
      <DialogActions>
        <button className="cancel-btn" onClick={closeEvent}>
          Fermer
        </button>
      </DialogActions>
    </Modal>
  ) : (
    <></>
  );

  return DialogDispaly;
}
