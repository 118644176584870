import http from "./http-common";
import authHeader from "../services/AuthHeader";
const getNewTourneeId = async () => {
  return await http.get("/GetNewTourneeId", {
    headers: authHeader(),
  });
};

export default {
  getNewTourneeId,
};
