import React from 'react'
import { useNavigate } from 'react-router-dom';
import AuthService from "../services/AuthService";
import "../css/TopAppBar.css"

export default function TopAppBar() {
   const navigate = useNavigate();
  const logoutEvent = () =>{
    AuthService.logout()
    navigate("/");
  }
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <div className='container d-flex justify-content-between align-items-center'>
      <img src="/img/logotms.png" alt="TMS" width="150" />
      <div className="dropdown">
        <button className="dropbtn">{user.fullName}</button>
        <div className="dropdown-content">
          <div className='dropdown-link' >{user.fullName}</div>
          <div className='dropdown-link' onClick={logoutEvent} >Déconnecter</div>
        </div>
      </div>
    </div>
  )
}
