import React, { createContext, useState } from "react";

export const LoadingGlobalContext = createContext();

export const LoadingGlobalProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  return (
    <LoadingGlobalContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </LoadingGlobalContext.Provider>
  );
};
