import { useContext, useEffect } from "react";
import Select, { components } from "react-select";
import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
import "../css/AffectationLivreurFormTabItem.css";
const { SingleValue, Option } = components;

const IconSingleValue = (props) => (
  <SingleValue {...props}>
    <div className="d-flex align-items-center">
      <img
        src={props.data.image}
        alt={props.data.fullname}
        style={{
          height: "30px",
          width: "30px",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      />
      {props.data.fullname}
    </div>
  </SingleValue>
);

const IconOption = (props) => (
  <Option {...props}>
    <div className="d-flex align-items-center">
      <img
        src={props.data.image}
        alt={props.data.fullname}
        style={{
          height: "30px",
          width: "30px",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      />
      {props.data.fullname}
    </div>
  </Option>
);

export default function AffectationLivreurFormTabItem({ closeEvent }) {
  const {
    stops,
    affectationStopsToLivreurFromSelectedOverleysHandler,
    getSelectedStopsIds,
    livreurs,
    selectedLivreurForAffectation,
    selectedTourneeId,
    changeselectedLivreurForAffectationHandler,
    changeselectedTourneeIdHandler,
    selectedVehiculeTypeId,
    setSelectLivreurForAffectation,
    setSelectTourneeId,
    invisibleTournees,
  } = useContext(ExpeditionContext);

  useEffect(() => {
    setSelectLivreurForAffectation(null);
    setSelectTourneeId(null);
  }, []);

  const GetTotalPrixStops = () => {
    var selectedStopsIdsList = getSelectedStopsIds();
    let sum = 0;
    stops
      .filter((m) => selectedStopsIdsList.includes(m.id) && m.stopType == 1)
      .map((stop) => {
        sum += stop.colis.reduce((a, v) => a + v.prix, 0);
      });
    return parseFloat(sum).toFixed(2);
  };

  const GetStatutStops = () => {
    var selectedStopsIdsList = getSelectedStopsIds();
    const countSelectedStops = selectedStopsIdsList.length;
    const countStopsAffectees = stops.filter(
      (stop) =>
        stop.isDone && stop.isVisible && selectedStopsIdsList.includes(stop.id)
    ).length;

    if (countSelectedStops == 0) return "Non affectée";
    else if (countSelectedStops == countStopsAffectees) return "Affectées";
    else if (countStopsAffectees == 0) return "Non affectée";
    else return "Mixtes";
  };

  const GetTotalCountAffecteesStopsByTournneId = (tourneeId) => {
    return stops.filter((m) => m.isDone && m.tourneeId == tourneeId).length;
  };

  const GetTotalPrixAffecteesStopsByTournneId = (tourneeId) => {
    let sum = 0;
    stops
      .filter((m) => m.isDone && m.tourneeId == tourneeId && m.stopType == 1)
      .map((stop) => {
        sum += stop.colis.reduce((a, v) => a + v.prix, 0);
      });
    return parseFloat(sum).toFixed(2);
  };

  const affecterEvent = () => {
    //Affectation Livreur
    if (selectedLivreurForAffectation) {
      affectationStopsToLivreurFromSelectedOverleysHandler();
      closeEvent();
    }
  };

  const closeDrawerEvent = () => {
    changeselectedLivreurForAffectationHandler("");
    closeEvent();
  };

  const changeLivreurEvent = (livreur) => {
    if (livreur && livreur.id)
      changeselectedLivreurForAffectationHandler(livreur.id);
    else changeselectedLivreurForAffectationHandler("");
  };

  const changeTourneeEvent = (e) => {
    changeselectedTourneeIdHandler(e.target.value);
  };

  const stopsCount = getSelectedStopsIds().length;
  const disabled = stopsCount == 0 || !selectedTourneeId;

  return (
    <>
      <div>
        <span className="font-weight-bold">Stops</span> : {stopsCount}
      </div>
      <hr className="mb-5" />

      <div>
        <span className="font-weight-bold">Total Cmd</span> :{" "}
        {GetTotalPrixStops()} Dh
      </div>
      <hr className="mb-5" />

      <div>
        <span className="font-weight-bold">Statut</span> : {GetStatutStops()}
      </div>
      <hr className="mb-5" />

      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 2000 }),
        }}
        placeholder="Sélectionner un livreur"
        components={{ SingleValue: IconSingleValue, Option: IconOption }}
        options={livreurs.filter(
          (m) => m.vehiculetypeId == selectedVehiculeTypeId && m.isVisible
        )}
        defaultValue={selectedLivreurForAffectation}
        onChange={changeLivreurEvent}
        menuPortalTarget={document.body}
        menuShouldBlockScroll={true}
        getOptionValue={(option) => option.id}
      />

      {selectedLivreurForAffectation ? (
        <div variant="standard" style={{ width: "100%", marginTop: "10px" }}>
          <div
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            name="radio-buttons-group"
            onChange={changeTourneeEvent}
          >
            {selectedLivreurForAffectation.tournees
              .filter((m) => !invisibleTournees.includes(m))
              .map((tournee) => (
                <div key={tournee} className="card">
                  <input
                    key={tournee}
                    type="radio"
                    value={tournee}
                    id={"trn" + tournee}
                    name="tournee"
                  />
                  <label
                    htmlFor={"trn" + tournee}
                    className="ml-5 tournee-label"
                  >
                    Tournée N° {tournee}
                  </label>
                  <div className="tournee-sub-info">
                    Stops : {GetTotalCountAffecteesStopsByTournneId(tournee)}
                  </div>
                  <div className="tournee-sub-info">
                    Total Cmd :{GetTotalPrixAffecteesStopsByTournneId(tournee)}{" "}
                    Dh
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : null}
      <div className="mt-20">
        <button className="cancel-btn" onClick={closeDrawerEvent}>
          Annuler
        </button>
        <button
          className="validate-btn"
          disabled={disabled || !selectedTourneeId}
          onClick={affecterEvent}
        >
          Valider
        </button>
      </div>
    </>
  );
}
