import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthService from "../services/AuthService"
import { useNavigate } from 'react-router-dom';
import { styled } from "@mui/material"

const BoostrapInput = styled(TextField)(({ theme }) => ({
    borderRadius: 100,
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://tms.com/">
        Marjane TMS
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide(props) {
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
 
    const email = data.get("email");
    const password =  data.get("password");
   

    if(email,password){
      AuthService.login(email,password)
      .then(() => {
          navigate("/dispatch");
          window.location.reload();
        })
      .catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(/img/loginbackground.png)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            
            <img src="/img/logotms.png" alt="TMS" width="300" height="80" />
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <BoostrapInput
                margin="normal"
                required
                fullWidth
                id="email"
                label="Identifiant"
                name="email"
                autoComplete="email"
                autoFocus
                style={{ padding: "5px", borderRadius: "50rem" , margin:"4px 4px", marginRight:"15px" }}
              />
              <BoostrapInput
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                style={{ padding: "5px", borderRadius: "50rem" , margin:"4px 4px", marginRight:"15px" }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{
                      backgroundColor: "#00AFAA",
                      borderColor: "#00AFAA"
                }}
              >
                Je me connecte
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
