import http from "./http-common";
import authHeader from "../services/AuthHeader";
const getAll = async (livreurIds) => {
  const data = await http.post("/GetLivreurs", livreurIds, {
    headers: authHeader(),
  });
  return data;
};

const saveLivreurNewLocalisation = async (data) => {
  return await http.post("/SaveLivreurNewLocalisation", data, {
    headers: authHeader(),
  });
};
export default {
  getAll,
  saveLivreurNewLocalisation,
};
