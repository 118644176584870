import React, { useContext, useState, useEffect } from "react";
import Modal from 'react-modal';
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { OverlayMapContext } from "../contexts/OverlayMapContextProvider";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CreateIcon from '@mui/icons-material/Create';
import HistoryIcon from '@mui/icons-material/History';
import AffectationLivreurFormTabItem from "./AffectationLivreurFormTabItem";
import AjournementFormTabItem from "./AjournementFormTabItem";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
Modal.setAppElement('#root');
export default function AffectationLivreurDialog() {
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState("1");

  const { isRightClickOverlay, setIsRightClickOverlay } =
    useContext(OverlayMapContext);

  const openEvent = () => {
    setOpen(true);
  };

  const closeEvent = () => {
    setIsRightClickOverlay(false);
    setOpen(false);
  };
  const changeTabEvent = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    if (isRightClickOverlay) openEvent();
    else closeEvent();
  }, [isRightClickOverlay]);

  return (
    <Modal
      isOpen={open} 
      onRequestClose={closeEvent}
      style={customStyles}
      aria-labelledby="customized-dialog-title"
    >
      <BootstrapDialogTitle  onClose={closeEvent}>
      </BootstrapDialogTitle>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue} centered>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              centered
              onChange={changeTabEvent}
              aria-label="lab API tabs example"
            >
              <Tab icon={<CreateIcon />} label="Affecter" value="1" />
              <Tab icon={<HistoryIcon />} label="Reporter" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: "0px" }}>
            <AffectationLivreurFormTabItem closeEvent={closeEvent} />
          </TabPanel>
          <TabPanel value="2" style={{ padding: "0px" }}>
            <AjournementFormTabItem closeEvent={closeEvent} />
          </TabPanel>
        </TabContext>
      </Box>
    </Modal>
  );
}
