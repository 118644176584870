import { useContext } from "react";
import { ExpeditionContext } from "../contexts/ExpeditionContextProvider";
import RightDrawerLivreurTourneesListItem from "./RightDrawerLivreurTourneesListItem";
import "../css/RightDrawerLivreurItem.css";
import "../css/RoundCheckBox.css";
export default function RightDrawerLivreurItem({ livreur }) {
  const { addTourneeToLivreurByIdHandler, changeLivreurVisibliteHandler } =
    useContext(ExpeditionContext);

  const TourneeList = livreur.tournees.map((tourneeId) => (
    <RightDrawerLivreurTourneesListItem
      key={tourneeId}
      tourneeId={tourneeId}
      livreur={livreur}
    />
  ));
  return (
    <>
      <div className=" d-flex align-items-center livreur-info-item">
        <div className="round mr-15">
          <input
            type="checkbox"
            className="round-checkbox-input"
            checked={livreur.isVisible}
            onChange={(e) =>
              changeLivreurVisibliteHandler(livreur, e.target.checked)
            }
            id={"checkbox-" + livreur.id}
          />
          <label
            htmlFor={"checkbox-" + livreur.id}
            className="round-checkbox-label"
          ></label>
        </div>
        <div className="p-relative">
          <img
            className="img-circle"
            alt={livreur.fullname}
            src={livreur.image}
            width="50px"
            height="50px"
          />
          <div
            className="livreur-color-circle"
            style={{ backgroundColor: livreur.color }}
          ></div>
        </div>
        <div className="w-100">
          <div>{livreur.fullname}</div>
          <div>{TourneeList}</div>
        </div>
        <button
          className="add-tournee-btn"
          onClick={() => addTourneeToLivreurByIdHandler(livreur.id)}
        >
          + {livreur.tournees.length}
        </button>
      </div>
      <hr />
    </>
  );
}
