import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ExpeditionContextProvider } from "./contexts/ExpeditionContextProvider";
import { MapContextProvider } from "./contexts/MapContextProvider";
import { OverlayMapContextProvider } from "./contexts/OverlayMapContextProvider";
import { LoadingGlobalProvider } from "./contexts/LoadingGlobalProvider";
// import { ConfirmProvider } from "material-ui-confirm";
import App from "./App";
const root = createRoot(document.getElementById("root")); // createRoot(container!) if you use TypeScript
root.render(
  <Router>
    <LoadingGlobalProvider>
      <MapContextProvider>
        <OverlayMapContextProvider>
          <ExpeditionContextProvider>
            <App />
          </ExpeditionContextProvider>
        </OverlayMapContextProvider>
      </MapContextProvider>
    </LoadingGlobalProvider>
  </Router>
);
