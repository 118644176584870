import React from "react";
import "../css/LivreurSearchBar.css"
export default function LivreurSearchBar({
  showLivreurIsVisible,
  setShowLivreurIsVisible,
  searchLivreurValue,
  setSearchLivreurValue,
}) {
  const searchLivreurChangeEvent = (e) => {
    const value = e.target.value;
    setSearchLivreurValue(value);
  };
  const changeVisibliteFilterEvent = (e) => {
    const value = e.target.value;
    setShowLivreurIsVisible(value);
  };
  return (
    <div
      className="d-flex justify-content-between align-items-center  m-10"
    >
      <div className="d-flex ">
          <div className="mr-15">
          <input type="radio" 
              checked={showLivreurIsVisible == "true" || showLivreurIsVisible == true}
              id="toutlivreur" 
              value={true} className="mr-4"
              onChange={changeVisibliteFilterEvent}
                 />
          <label htmlFor="toutlivreur">Tout</label>
        </div>
        <div>
          <input type="radio" 
              checked={showLivreurIsVisible == "false" || showLivreurIsVisible == false}
              id="actiflivreur" 
              value={false} className="mr-4"
              onChange={changeVisibliteFilterEvent}
           />
          <label htmlFor="actiflivreur">Actif</label>
        </div>
      </div>
      <div className="search">
      <i className="las la-search"></i>
      <input 
        className="search-livreur-input"
        placeholder="Chercher..."
        value={searchLivreurValue}
          onChange={searchLivreurChangeEvent} />
</div>

    </div>
  );
}
